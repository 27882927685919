<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="20px"
  class="dialog-title"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <svg-icon
      [src]="'assets/icons/export-light.svg'"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="mat-primary-color"
      [svgStyle]="{ 'width.px': 40, 'height.px': 40 }"
    ></svg-icon>
    <h3>Exporter les données</h3>
  </div>
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content
  class="mat-typography"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="20px"
>
  <form [formGroup]="exportForm" (ngSubmit)="submitExportForm()">
    <formly-form
      [model]="exportModel"
      [fields]="fieldsForm"
      [options]="options"
      [form]="exportForm"
    ></formly-form>
  </form>

  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
    class="dialog-actions"
  >
    <button mat-stroked-button mat-dialog-close [mat-dialog-close]="true">
      Annuler
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="submitExportForm()"
      [disabled]="!exportForm.valid"
    >
      Exporter
    </button>
  </div>
</mat-dialog-content>
