import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import french from '../other/countries/french.json';

export interface KeyValuePair {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  lang = 'fr';
  constructor(private cookieService: SsrCookieService) {
    this.lang = this.cookieService.get('current-language') || 'fr';
  }

  getLocalizedCountries(): KeyValuePair[] {
    let countries: KeyValuePair[] = [];
    let tempSet: Set<any>;
    switch (this.lang) {
      case 'fr':
        tempSet = new Set(Object.entries(french));
        break;
      default:
        tempSet = new Set(Object.entries(french));
        break;
    }
    for (const country of tempSet) {
      countries.push({ key: country[0], value: country[1] });
    }
    return countries;
  }

  getLocalizedCountryFromCode(code: string): string {
    let country = '';
    let tempSet: Set<any>;
    switch (this.lang) {
      case 'fr':
        tempSet = new Set(Object.entries(french));
        break;
      default:
        tempSet = new Set(Object.entries(french));
        break;
    }
    for (const c of tempSet) {
      if (c[0] === code) {
        country = c[1];
        break;
      }
    }
    return country;
  }
}
