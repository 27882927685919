<mat-form-field style="width: 100%">
  <mat-label>{{ to["label"] }}</mat-label>
  <input
    matInput
    [matAutocomplete]="auto"
    [formControl]="getFormControl()"
    [formlyAttributes]="field"
    (input)="onInputChange($event)"
    [readonly]="to.readonly"
    [placeholder]="
      to.placeholder
        ? to.required
          ? to.placeholder + ' *'
          : to.placeholder
        : ''
    "
    [required]="to.required ?? false"
  />

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn(filteredLocalizedCountriesSync)"
    #_auto
  >
    @for (item of filteredLocalizedCountriesSync; track $index) {
    <mat-option [value]="item.key">
      {{ item.value }}
    </mat-option>
    }
  </mat-autocomplete>

  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>

  <mat-hint *ngIf="to.description">
    {{ to.description }}
  </mat-hint>
</mat-form-field>
