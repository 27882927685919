import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-page-not-authorized',
  templateUrl: './page-not-authorized.component.html',
  styleUrl: './page-not-authorized.component.scss'
})
export class PageNotAuthorizedComponent {

  constructor(private router: Router, private _location: Location) { }

  goToHomePage() {
    this._location.back();
  }
}
