<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="0px" class="page-not-found-container">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <svg-icon class="mat-warn-color" [src]="'assets/icons/search-not-found-light.svg'" fxLayout="row"
      fxLayoutAlign="center center" [svgStyle]="{ 'width.px': 150, 'height.px': 150 }"></svg-icon>
    <h1 class="mat-warn-color">Accès refusé</h1>
    <p class="mat-grey-color">
      Nous sommes désolés pour la gêne occasionnée. Il semble que vous essayez
      d'accéder à une page à laquelle vous n'avez pas accès.

    </p>
    <p class="mat-grey-color">
      Veuillez vérifier l'URL ou contactez votre administrateur.
    </p>
    <button mat-flat-button color="warn" (click)="goToHomePage()">
      Retour en arrière
    </button>
  </div>
</div>
