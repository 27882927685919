<div>
  <label
    [ngClass]="{
      disabled: to.disabled
    }"
    >{{ to["label"] }}</label
  >
  <div style="height: 6px"></div>
  <mat-form-field style="width: 100%">
    <mat-error>
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>

    <mat-radio-group
      [formControl]="getFormControl()"
      [formlyAttributes]="field"
    >
      <mat-radio-button *ngFor="let option of opts" [value]="option.value">
        {{ option.label }}
      </mat-radio-button>
      <!-- @for (item of props.options; track $index) {} -->
    </mat-radio-group>
    <input style="display: none" matInput />

    <mat-hint *ngIf="to.description">
      {{ to.description }}
    </mat-hint>
  </mat-form-field>
  @if(!to["disabled"]){ @if(showComment){
  <div style="height: 10px"></div>
  <mat-form-field style="width: 100%">
    <mat-label>Commentaire</mat-label>
    <input
      matInput
      type="text"
      [id]="key.toString() + '_comment'"
      [value]="comment"
    />
  </mat-form-field>
  } @if(showAttachment){ @if(showComment){
  <div style="height: 10px"></div>
  }
  <mat-form-field style="width: 100%; cursor: pointer">
    <mat-icon matPrefix (click)="fileUpload.click()">
      <svg-icon
        [src]="attachmentIcon"
        fxLayout="row"
        fxLayoutAlign="center center"
        [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
      ></svg-icon>
    </mat-icon>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      @if(attachment != null){
      <img [src]="attachment['url']" height="50" /> <br />
      }

      <input
        style="cursor: pointer"
        matInput
        readonly
        (click)="fileUpload.click()"
        [value]="fileInputText ? fileInputText : ''"
        placeholder="Pièce jointe"
      />

      <input
        type="file"
        style="display: none"
        (change)="onFileSelected($event)"
        accept="image/png, image/jpg, image/jpeg"
        #fileUpload
        [id]="key.toString() + '_attachment'"
      />
    </div>
    @if(attachment != null){
    <mat-icon matSuffix (click)="deleteFiles()">
      <svg-icon
        src="/assets/icons/delete-light.svg"
        fxLayout="row"
        fxLayoutAlign="center center"
        [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
      ></svg-icon>
    </mat-icon>
    }
  </mat-form-field>
  } }
</div>
