import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ApiService } from './service/api.service';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent {
  lang = this.cookieService.get('current-language') || 'fr';
  searchText: string = '';
  showResults = false;
  searchResults: any = {};
  apiEndpointList: any = {
    users: {
      key: 'users',
      label: 'Utilisateurs',
      icon: '3-user-light',
      path: 'users/edit/',
      titleCell: (element: any) =>
        `${element.first_name + ' ' + element.last_name}`,
      lineCell: (element: any) => `${element.email}`,
    },
    // legalEntities: {
    //   key: 'legal-entities',
    //   label: 'Entités légales',
    //   icon: 'building-hospital-light',
    //   path: 'legal-entities/',
    //   titleCell: (element: any) => `${element.name}`,
    //   lineCell: (element: any) =>
    //     `${element.address + ', ' + element.city + ' ' + element.postal_code}`,
    // },
    suppliers: {
      key: 'suppliers',
      label: 'Fournisseurs',
      icon: 'building-hospital-light',
      path: 'catalog/suppliers/',
      titleCell: (element: any) => `${element.legal_entity.name}`,
      lineCell: (element: any) =>
        `${
          element.legal_entity.address +
          ', ' +
          element.legal_entity.city +
          ' ' +
          element.legal_entity.postal_code
        }`,
    },
    legalEntities: {
      key: 'manufacturers',
      label: 'Fabricants',
      icon: 'building-hospital-light',
      path: 'catalog/manufacturers/',
      titleCell: (element: any) => `${element.legal_entity.name}`,
      lineCell: (element: any) =>
        `${
          element.legal_entity.address +
          ', ' +
          element.legal_entity.city +
          ' ' +
          element.legal_entity.postal_code
        }`,
    },
    medicalDevices: {
      key: 'medical-devices',
      label: 'Dispositifs médicaux',
      icon: 'health-clipboard-light',
      path: 'medical-devices/edit/',
      titleCell: (element: any) => `${element.name[this.lang]}`,
      lineCell: (element: any) =>
        `${
          element.family.name[this.lang] +
          ', ' +
          element.manufacturer.legal_entity.name
        }`,
    },
  };

  constructor(
    private api: ApiService,
    private router: Router,
    private cookieService: SsrCookieService
  ) {}

  applyFilter(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    if (this.searchText.length > 2) {
      this.showResults = true;
      Object.keys(this.apiEndpointList).forEach((endpoint: any) => {
        this.api
          .getAPIGlobalPaginated(
            this.apiEndpointList[endpoint].key,
            0,
            10,
            this.searchText
          )
          .subscribe((data) => {
            this.searchResults[endpoint] = data.data;
            this.updateResearchFindData();
          });
      });
    } else {
      this.searchResults = {};
      this.showResults = false;
      this.findDataInSearchRequest = false;
    }
  }
  resetSearchText() {
    this.searchText = '';
    this.searchResults = {};
    this.showResults = false;
  }

  getResultsToShow() {
    return Object.keys(this.searchResults);
  }

  findDataInSearchRequest = false;
  updateResearchFindData() {
    this.findDataInSearchRequest = false;
    let arr_properties = this.getResultsToShow();
    for (let i = 0; i < arr_properties.length; i++) {
      if (this.searchResults[arr_properties[i]].length > 0) {
        this.findDataInSearchRequest = true;
        break;
      }
    }
  }

  getHeaderLabel(key: string) {
    return this.apiEndpointList[key].label;
  }

  // RouterLink
  goToLink(key: string, id: string) {
    this.router.navigate([this.apiEndpointList[key].path, id]);
  }
}
