import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

// Material
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MaterialModule } from './material/material.module';

// Icon from SVG File
import { AngularSvgIconModule } from 'angular-svg-icon';

// Flex Layout
//import { FlexLayoutModule } from '@angular/flex-layout';
import { FlexLayoutServerModule } from '@angular/flex-layout/server';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

// PDF - Viewer
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

// LAYXO - Custom Component
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { TableDeleteDialogComponent } from './components/custom-table/dialog/delete-dialog/delete-dialog.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';

// LAYXO - Custom Services
import { SnackbarService } from './services/snackbar.service';

// NGX Formly
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyBuilderService } from './services/formly-builder.service';
// NGX Formly - Custom Inputs
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AcceptMedicalDeviceAlertDraftComponent } from '@app/shared/dialog/accept-medical-device-alert-draft/accept-medical-device-alert-draft.component';
import { CountriesService } from '@app/shared/services/countries.service';
import { DeleteDialogComponent } from './dialog/delete-dialog/delete-dialog.component';
import { EditDialogComponent } from './dialog/edit-dialog/edit-dialog.component';
import { MedicalDeviceAlertConcernedBatchesComponent } from './dialog/medical-device-alert-concerned-batches/medical-device-alert-concerned-batches.component';
import { SiteMissingSubscriptionComponent } from './dialog/site-missing-subscription/site-missing-subscription.component';
import { SiteSubscriptionIssueComponent } from './dialog/site-subscription-issue/site-subscription-issue.component';
import { UpdateAlertCorrectiveActionsComponent } from './dialog/update-alert-corrective-actions/update-alert-corrective-actions.component';
import { addonsExtension } from './formly/custom-input/addons.extension';
import { AutocompleteChipTypeComponent } from './formly/custom-input/autocomplete-chip-type/autocomplete-chip-type.component';
import { AutocompleteTypeComponent } from './formly/custom-input/autocomplete-type/autocomplete-type.component';
import { CountryInputComponent } from './formly/custom-input/country-input/country-input.component';
import { DatePickerComponent } from './formly/custom-input/date-picker/date-picker.component';
import { DividerFieldType } from './formly/custom-input/divider-input';
import { FilePickerComponent } from './formly/custom-input/file-picker/file-picker.component';
import { FormlyFieldFile } from './formly/custom-input/file.type';
import { FlexLayoutType } from './formly/custom-input/flex-layout.type';
import { IconInputComponent } from './formly/custom-input/icon-input/icon-input.component';
import { RepeatTypeComponent } from './formly/custom-input/repeat.type';
import { SampleControlCheckboxComponent } from './formly/custom-input/sample-control-checkbox/sample-control-checkbox.component';
import { SampleControlRadioComponent } from './formly/custom-input/sample-control-radio/sample-control-radio.component';
import { StepperTypeComponent } from './formly/custom-input/stepper-type/stepper-type.component';
import { TextTypeComponent } from './formly/custom-input/text-type/text-type.component';
import { FormlyWrapperAddons } from './formly/custom-wrapper/addons.wrapper';
import { PanelWrapperComponent } from './formly/custom-wrapper/panel.wrapper';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageNotAuthorizedComponent } from './pages/page-not-authorized/page-not-authorized.component';
import { CustomTranslatePipe } from './pipes/translation.pipe';
import { UtcToLocalPipe } from './pipes/utc-to-local/utc-to-local.pipe';
import { ExportDialogComponent } from './dialog/export-dialog/export-dialog.component';

// NGX Formly - Customs Validators

// EMAIL - Validator
export function EmailValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `"${field.formControl?.value}" n'est pas une adresse mail valide`;
}

// PASSWORD - Validator
// At least 8 characters in length / 1 Lowercase letters / 1 Uppercase letters / 1 Numbers / 1 Special characters
export function PasswordValidator(
  control: AbstractControl
): ValidationErrors | null {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*].{8,}/.test(
    control.value
  )
    ? null
    : { password: true };
}
export function PasswordValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `Votre mot de passe doit contenir au moins 8 caractères, une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial`;
}

// PASSWORD CONFIRMATION - Validator
//Same as password
export function PasswordConfirmationValidator(
  control: AbstractControl
): ValidationErrors | null {
  const { password, password_confirmation } = control.value;

  // avoid displaying the message error when values are empty
  if (!password_confirmation || !password) {
    return null;
  }

  if (password_confirmation === password) {
    return null;
  }

  return { password_confirmation: true };
}
export function PasswordConfirmationValidatorMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `Les mots de passe ne correspondent pas`;
}

// DATE - Validator
export function DateValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `"${field.formControl?.value}" n'est pas une date valide`;
}
export function DateValidator(control: AbstractControl): ValidationErrors {
  return /^(3[01]|[12][0-9]|0?[1-9])(\/|-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/.test(
    control.value
  )
    ? { date: null }
    : { date: true };
}

export const EUROPEAN_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

export const enum UtcToLocalTimeFormat {
  DATE_TIME = 'datetime',       // 'd/M/yy, h:mm'                      - 15/6/15, 9:03
  DATE = 'date',   // 'd/M/yy'                            - 15/6/15
  TIME = 'time',   // 'h:mm'                              - 9:03
}

@NgModule({
  declarations: [
    CustomTableComponent,
    TableDeleteDialogComponent,
    FlexLayoutType,
    FormlyFieldFile,
    FilePickerComponent,
    SearchbarComponent,
    TextTypeComponent,
    DatePickerComponent,
    CustomTranslatePipe,
    UtcToLocalPipe,
    StepperTypeComponent,
    FormlyWrapperAddons,
    EditDialogComponent,
    DeleteDialogComponent,
    AutocompleteTypeComponent,
    AutocompleteChipTypeComponent,
    RepeatTypeComponent,
    PanelWrapperComponent,
    PageNotFoundComponent,
    PageNotAuthorizedComponent,
    SiteMissingSubscriptionComponent,
    SiteSubscriptionIssueComponent,
    AcceptMedicalDeviceAlertDraftComponent,
    UpdateAlertCorrectiveActionsComponent,
    SampleControlRadioComponent,
    SampleControlCheckboxComponent,
    IconInputComponent,
    MedicalDeviceAlertConcernedBatchesComponent,
    CountryInputComponent,
    ExportDialogComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FlexLayoutServerModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FormlyMaterialModule,
    AngularSvgIconModule.forRoot(),
    FormlyModule.forRoot({
      extras: {
        resetFieldOnHide: false,
      },
      types: [
        {
          name: 'flex-layout',
          component: FlexLayoutType,
          defaultOptions: {
            defaultValue: {},
          },
        },
        { name: 'date', component: DatePickerComponent },
        { name: 'divider', component: DividerFieldType },
        { name: 'file', component: FormlyFieldFile },
        { name: 'file-v2', component: FilePickerComponent },
        { name: 'stepper', component: StepperTypeComponent, wrappers: [] },
        { name: 'text', component: TextTypeComponent },
        { name: 'autocomplete', component: AutocompleteTypeComponent },
        { name: 'autocomplete-chip', component: AutocompleteChipTypeComponent },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'icon-input', component: IconInputComponent },
        { name: 'control-radio', component: SampleControlRadioComponent },
        { name: 'control-checkbox', component: SampleControlCheckboxComponent },
        { name: 'country', component: CountryInputComponent },
      ],
      wrappers: [
        { name: 'addons', component: FormlyWrapperAddons },
        { name: 'panel', component: PanelWrapperComponent },
      ],
      extensions: [
        { name: 'addons', extension: { onPopulate: addonsExtension } },
      ],

      validators: [
        { name: 'email', validation: Validators.email },
        { name: 'password', validation: PasswordValidator },
        {
          name: 'fieldMatch',
          validation: PasswordConfirmationValidator,
        },
        { name: 'date', validation: DateValidator },
      ],
      validationMessages: [
        { name: 'email', message: EmailValidatorMessage },
        { name: 'password', message: PasswordValidatorMessage },
        {
          name: 'password_confirmation',
          message: PasswordConfirmationValidatorMessage,
        },
        { name: 'date', message: DateValidatorMessage },
      ],
    }),
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FlexLayoutServerModule,
    CustomTableComponent,
    AngularSvgIconModule,
    FormlyMaterialModule,
    FormlyModule,
    SearchbarComponent,
    CustomTranslatePipe,
    UtcToLocalPipe,
    EditDialogComponent,
    DeleteDialogComponent,
    NgxExtendedPdfViewerModule,
    PageNotFoundComponent,
    PageNotAuthorizedComponent,
  ],
  providers: [
    SnackbarService,
    CountriesService,
    FormlyBuilderService,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
