import { Pipe, PipeTransform } from '@angular/core';
import { UtcToLocalTimeFormat } from '@app/shared/shared.module';



@Pipe({
  name: 'utcToLocal',
})
export class UtcToLocalPipe implements PipeTransform {

  transform(date: string, args?: any): string {
    return this.convertUtcToLocal(date, args);
  }

  private convertUtcToLocal(
    utcDate: string,    // UTC ISO-8601
    format: UtcToLocalTimeFormat = UtcToLocalTimeFormat.DATE
  ): string {

    utcDate = utcDate; // Add Z to the end of the date to indicate that it is UTC

    var browserLanguage = navigator.language;

    if (format === UtcToLocalTimeFormat.DATE_TIME) {
      let date = new Date(utcDate).toLocaleDateString(browserLanguage);
      let time = new Date(utcDate).toLocaleTimeString(browserLanguage);

      return `${date} à ${time}`;
    }
    else if (format === UtcToLocalTimeFormat.DATE) {
      return new Date(utcDate).toLocaleDateString(browserLanguage);
    }
    else if (format === UtcToLocalTimeFormat.TIME) {
      return new Date(utcDate).toLocaleTimeString(browserLanguage);
    }
    else {
      console.error(
        `Do not have logic to format utc date, format:${format}`
      );
      return new Date(utcDate).toString();
    }
  }
}
