import { Injectable } from '@angular/core';
import { ApiService } from '@app/data/api/api.service';

const routes = {
  export: (
    dataType: ExportDataType,
    format: ExportFormat,
    siteId?: string | string[]
  ) =>
    `/export/${dataType}/${format}${siteId
      ? `?siteId=${Array.isArray(siteId) ? `[${siteId.join(',')}]` : siteId}`
      : ''
    }`,
};

export type ExportDataType =
  | 'registered_devices'
  | 'medical_device_batchs'
  | 'users';

export type ExportFormat = 'excel' | 'csv';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(private apiService: ApiService) { }

  export(
    dataType: ExportDataType,
    format: ExportFormat,
    siteId?: string | string[]
  ) {
    return this.apiService.getBase64(routes.export(dataType, format, siteId));
  }
}
