import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@env';
import { Observable, map } from 'rxjs';
import { ApiService } from '../api/api.service';
import { MedicalDeviceBatch } from '../models/medical-device-batches';

const routes = {
  medicalDeviceBatches: '/medical-device-batches',
  medicalDeviceBatchesWithSampleContros:
    '/medical-device-batches/with-samples-controls',
  medicalDeviceBatch: (id: string) => `/medical-device-batches/${id}`,
  generateCertificate: (id: string) => `/medical-device-batches/${id}/generate-certificate`,
};

const BASE_URL = env.apiURL;
@Injectable({
  providedIn: 'root',
})
export class MedicalDeviceBatchService {
  constructor(private apiService: ApiService, private http: HttpClient) { }

  getAll(): Observable<MedicalDeviceBatch[]> {
    return this.apiService.get(routes.medicalDeviceBatches);
  }

  getAllWithSampleControls(
    field?: string,
    value?: string
  ): Observable<MedicalDeviceBatch[]> {
    const url_req =
      field && value
        ? `${routes.medicalDeviceBatchesWithSampleContros}?filter.${field}=${value}`
        : routes.medicalDeviceBatchesWithSampleContros;
    return this.apiService.get(url_req).pipe(
      map((data) => {
        return data['data'] ?? data;
      })
    );
  }

  getAllWithFilters(field: string, value?: any) {
    return this.apiService
      .get(`${routes.medicalDeviceBatches}?filter.${field}=${value}`)
      .pipe(
        map((data) => {
          return data['data'] ?? data;
        })
      );
  }

  getAllForMedicalDeviceWithBatchNumberFilter(
    id: string,
    batchNumber?: string
  ): Observable<MedicalDeviceBatch[]> {
    return this.apiService
      .get(
        `${routes.medicalDeviceBatches
        }?filter.registered_device.medical_device.id=${id}${batchNumber ? `&filter.batch_number=$ilike:${batchNumber}` : ''
        }`
      )
      .pipe(
        map((data) => {
          return data['data'] ?? data;
        })
      );
  }

  getAllForRegisteredDeviceWithBatchNumberFilter(
    id: string,
    batchNumber?: string
  ): Observable<MedicalDeviceBatch[]> {
    return this.apiService
      .get(
        `${routes.medicalDeviceBatches}?filter.registered_device.id=${id}${batchNumber ? `&filter.batch_number=$ilike:${batchNumber}` : ''
        }`
      )
      .pipe(
        map((data) => {
          return data['data'] ?? data;
        })
      );
  }

  getWithStringFilter(filter: any): Observable<any> {
    return this.apiService.get(
      `${routes.medicalDeviceBatches}?filter.${filter}`
    );
  }

  getSingle(id: string): Observable<MedicalDeviceBatch> {
    return this.apiService.get(routes.medicalDeviceBatch(id));
  }

  create(
    medicalDeviceBatch: MedicalDeviceBatch
  ): Observable<MedicalDeviceBatch[]> {
    return this.apiService.post(
      routes.medicalDeviceBatches,
      medicalDeviceBatch
    );
  }

  update(
    id: string,
    medicalDeviceBatch: MedicalDeviceBatch
  ): Observable<MedicalDeviceBatch> {
    return this.apiService.patch(
      routes.medicalDeviceBatch(id),
      medicalDeviceBatch
    );
  }

  getCertificate(
    id: string,
    certificateId: string,
    fileId: string
  ): Observable<any> {
    return this.apiService.getBase64(
      `${routes.medicalDeviceBatches}/${id}/certificate/${certificateId}/file/${fileId}`
    );
  }

  getCertificateURL(id: string, certificateId: string, fileId: string): string {
    return `${BASE_URL}${routes.medicalDeviceBatches}/${id}/certificate/${certificateId}/file/${fileId}`;
  }

  regenerateCertificate(batchId: string) {
    return this.apiService.getBase64(
      routes.generateCertificate(batchId)
    );
  }
}
